import React from 'react';
import './ReceiptPage.css';
import Receipt from "../components/Receipt";
import {getFromLocalStorage} from "../localStorageUtils";
import {useLocation} from "react-router-dom";
import {Message} from "../context/PollingContext";

const ReceiptPage: React.FC = () => {
    const location = useLocation();
    const message = location.state?.message
        ? location.state.message as Message
        : JSON.parse(localStorage.getItem('message') || '{}') as Message;
    const currency = getFromLocalStorage('currency') || '$';
    const date = getFromLocalStorage('date') ? new Date(getFromLocalStorage('date')) : new Date();
    const receiptNumber = getFromLocalStorage('receiptNumber') || '1';

    return (
        <div className="receipt-page-container">
            <Receipt
                open={true}
                onClose={() => window.close()}
                paymentMethod={message?.type}
                price={message.amount}
                currency={currency}
                date={date}
                receiptNumber={receiptNumber}
                cardNumber={message?.card?.cardNumber}
                accountNumber={message?.payByTransfer?.originatingAccountNumber}
                expiry={message?.card?.expiry}
                bankName={message?.payByTransfer?.originatingBankName}
                phoneNumber={message?.payAttitude?.phoneNumber}
                beneficiaryAccountNumber={message?.payByTransfer?.beneficiaryAccountNumber}
                beneficiaryAccountName={message?.payByTransfer?.beneficiaryAccountName}
                originatingAccountNumber={message?.payByTransfer?.originatingAccountNumber}
                originatingAccountName={message?.payByTransfer?.originatingAccountName}
                originatingBankName={message?.payByTransfer?.originatingBankName}
                transactionDate={message?.payByTransfer?.transactionDate}
                status={message?.status}
                referenceID={message?.payByTransfer?.referenceID}
            />
        </div>
    );
};

export default ReceiptPage;
