import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

interface PollingContextProps {
    setId: (id: string) => void; // Function to update the ID dynamically
    setTimer: (value: boolean) => void;
    isTimerActive: boolean;
}

const PollingContext = createContext<PollingContextProps | undefined>(undefined);

export enum PaymentType {
    CARD = 'Card',
    PAY_ATTITUDE = 'PayAttitude',
    PAY_BY_TRANSFER = 'PayByTransfer',
}

export interface PayAttitudeDetails {
    phoneNumber: string,
}

export interface CardPayloadDetails {
    cardNumber: string,
    expiry: string
}

export interface PayByTransferDetails {
    beneficiaryAccountNumber: string;
    beneficiaryAccountName: string;
    transactionDate: string;
    originatingBankName: string;
    originatingAccountName: string;
    originatingAccountNumber: string;
    sessionId: string;
    referenceID: string;
}

export interface Message {
    type: PaymentType,
    webPurchaseId: string,
    status: string,
    amount: string,
    payAttitude?: PayAttitudeDetails,
    card?: CardPayloadDetails,
    payByTransfer?: PayByTransferDetails
}

export interface PollingResponse {
    amount: number;
    currencyCodeNumber: string;
    status: string;
    finalSummary: Message;
    redirectUrl: string;
}

interface PollingProviderProps {
    children: ReactNode;
}

export const PollingProvider: React.FC<PollingProviderProps> = ({ children }) => {
    const [id, setId] = useState<string | null>(null);
    const [isTimerActive, setIsTimerActive] = useState<boolean>(false);
    const navigate = useNavigate();

    const timerStartRef = useRef<number | null>(null);

    const setTimer = (value: boolean) => {
        setIsTimerActive(value);
        if (value) {
            // If turning on, mark the start time
            timerStartRef.current = Date.now();
        } else {
            // If turning off, reset the timer reference
            timerStartRef.current = null;
        }
    };


    useEffect(() => {
        if (!id) return;

        const poll = async () => {
            try {
                const response = await axios.get<PollingResponse>(
                    `${process.env.REACT_APP_API_BASE_URL}/api/payment/summary/${id}`
                );
                const pollingResponse = response.data;
                if (isTimerActive && timerStartRef.current) {
                    const elapsed = Date.now() - timerStartRef.current;
                    if (elapsed >= 121000) {
                        // 2 minutes have passed
                        navigate('/failure', {
                            state: {
                                id,
                                finalStatus: pollingResponse,
                                message: `Purchase ID ${id} has EXPIRED. Retry is not allowed.`
                            },
                        });
                        return;
                    }
                }
                handlePollingMessage(pollingResponse);
            } catch (error) {
                console.error("Error during polling:", error);
            }
        };

        // Poll every 15 seconds
        const interval = setInterval(poll, 15000);

        // Immediately call poll once when this effect runs
        poll();

        return () => clearInterval(interval);
    }, [id, isTimerActive, navigate]);

    const handlePollingMessage = (message: PollingResponse) => {
        if (message.finalSummary?.status.toLowerCase() === "approved") {
            navigate('success', {
                state: { message }
            });
        } else if (message.finalSummary?.status.toLowerCase() === "declined") {
            navigate('/failure', {
                state: { id, finalStatus: message, message: `Purchase ID ${id} has been DECLINED. Retry is allowed.` },
            });
        } else if (message.status === 'EXPIRED') {
            navigate('/failure', {
                state: { id, finalStatus: message, message: `Purchase ID ${id} has EXPIRED. Retry is not allowed.` },
            });
        }
    };

    return (
        <PollingContext.Provider value={{ setId, setTimer, isTimerActive }}>
            {children}
        </PollingContext.Provider>
    );
};

export const usePolling = (): PollingContextProps => {
    const context = useContext(PollingContext);
    if (!context) {
        throw new Error("usePolling must be used within a PollingProvider");
    }
    return context;
};
