import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

const SuccessPage: React.FC = () => {
    const [countdown, setCountdown] = useState(30);
    const navigate = useNavigate();
    const linkRef = useRef<HTMLAnchorElement>(null);
    const location = useLocation();
    const { message } = location.state || {};

    useEffect(() => {
        // Attempt to open in the background by simulating a "middle-click".
        // This may be blocked or ignored by some browsers.
        if (linkRef.current) {
            linkRef.current.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    button: 1  // button=1 is the "middle-click" in many browsers
                })
            );
        }
    }, []);

    useEffect(() => {
        if (message) {
            localStorage.setItem('message', JSON.stringify(message.finalSummary));
        }

        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        if (countdown === 0) {
            const redirectBaseUrl = message.redirectUrl === "PL" || message.redirectUrl === "PAYMENT_LINK" ? "https://www.ziroopay.com/" : message.redirectUrl;
            const urlWithParams = new URL(redirectBaseUrl);
            urlWithParams.searchParams.append("status", message.status);

            window.location.href = urlWithParams.toString();
        }

        return () => clearInterval(timer);
    }, [countdown, navigate]);

    const openReceiptPage = () => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    };

    return (
        <div className="success-container">
            <div className="success-icon">✔️</div>
            <h1>Payment Successful!</h1>
            <p>Your payment was successfully processed. You will be redirected to the original page in {countdown} seconds. Thank you for your purchase, and we hope you enjoy our service!</p>

            <button className="download-receipt-button" onClick={openReceiptPage}>
                If you want to download and check your receipt, click HERE
            </button>

            <p className="powered-by">Powered by ZirooPay</p>
            <Link
                to="/receipt"
                target="_blank"
                state={{ message: message.finalSummary }}
                ref={linkRef}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default SuccessPage;
