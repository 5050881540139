import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const FailurePage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id, message, finalStatus } = location.state || {};

    // Keep track of a 10-second countdown
    const [countdown, setCountdown] = useState(10);

    // Function to handle the actual redirect
    const handleRedirect = () => {
        // Decide the base URL from finalStatus or message
        let redirectBaseUrl;
        if (finalStatus?.redirectUrl === "PL" || finalStatus?.redirectUrl === "PAYMENT_LINK") {
            redirectBaseUrl = "https://www.ziroopay.com/";
        } else if (finalStatus?.redirectUrl) {
            redirectBaseUrl = finalStatus.redirectUrl;
        } else {
            // Fallback or default URL if needed
            redirectBaseUrl = "https://www.ziroopay.com/";
        }

        // Build the final URL with the "status" parameter if available
        const urlWithParams = new URL(redirectBaseUrl);
        if (finalStatus?.status) {
            urlWithParams.searchParams.append("status", finalStatus.status);
        }

        // Redirect
        window.location.href = urlWithParams.toString();
    };

    // useEffect for the countdown
    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        // When the countdown hits 0, redirect
        if (countdown === 0) {
            handleRedirect();
        }

        // Cleanup the interval on unmount
        return () => clearInterval(timer);
    }, [countdown]);

    return (
        <div className="failure-container">
            <div className="failure-icon">❌</div>
            <h1>Payment Failed</h1>
            {message ? (
                <p>{message}</p>
            ) : (
                <p>Unfortunately, your payment could not be processed.</p>
            )}

            <p>You will be redirected to the original page in {countdown} seconds.</p>

            <button className="cancel-button" onClick={handleRedirect}>
                Cancel
            </button>

            <p className="powered-by">Powered by ZirooPay</p>
        </div>
    );
};

export default FailurePage;
