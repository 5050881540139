import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes, useNavigate} from "react-router-dom";
import CheckoutPage from "./pages/CheckoutPage";
import SuccessPage from "./pages/SuccessPage";
import FailurePage from "./pages/FailurePage";
import ReceiptPage from "./pages/ReceiptPage";
import SecureCheckPage from "./pages/SecureCheckPage";

const App: React.FC = () => {
    const navigate = useNavigate();
    const [navigateToUrl, setNavigateToUrl] = useState<string | null>(null);
    const [renderTrigger, setRenderTrigger] = useState(0);


    // Set up the service worker message listener once on component mount
    /*useEffect(() => {
        const handleServiceWorkerMessage = (event: MessageEvent) => {
            console.log('Received message from service worker:', event);

            if (event.data && event.data.type === 'NAVIGATE' && event.data.url) {
                // Create a URL object to parse the URL
                const fullUrl = new URL(event.data.url, window.location.origin);
                // Extract only the relative path and query string
                const relativePath = `${fullUrl.pathname}${fullUrl.search}`;

                console.log('Setting navigateToUrl:', relativePath); // This should now be relative
                navigate(`${relativePath}&timestamp=${Date.now()}`, { replace: true });
                setNavigateToUrl(`${relativePath}&timestamp=${Date.now()}`);
            }
        };

        navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    }, []);*/

    // Perform navigation when navigateToUrl is set
    useEffect(() => {
        console.log('navigateToUrl changed:', navigateToUrl);
        if (navigateToUrl) {
            const uniqueUrl = `${navigateToUrl}&key=${Date.now()}`;
            navigate(uniqueUrl, { replace: true });
            setNavigateToUrl(uniqueUrl);
            setRenderTrigger(prev => prev + 1); // Force re-render
        }
    }, [navigateToUrl, navigate]);

    return (
        <Routes key={renderTrigger}>
            {/*<Route path="/checkout" element={<ImmediateCheckoutPage/>}/> */}
            <Route path="/checkout/:id" element={<CheckoutPage/>}/>
            <Route path="/success" element={<SuccessPage/>}/>
            <Route path="/failure" element={<FailurePage/>}/>
            <Route path="/receipt" element={<ReceiptPage/>}/>
            <Route path="/secure-check/:id" element={<SecureCheckPage />} />
        </Routes>
    );
};

export default App;
