import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {PollingProvider} from "./context/PollingContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <PollingProvider>
                <App/>
            </PollingProvider>
        </BrowserRouter>
    </React.StrictMode>
);

const handleServiceWorkerMessage = (event: MessageEvent) => {
    console.log('📨 Received message from service worker:', event);

    if (!event.data) {
        console.warn('⚠️ Received an empty message!', event);
        return;
    }

    if (event.data.type === 'NAVIGATE' && event.data.url) {
        console.log('🚀 Navigating to:', event.data.url);
        window.location.href = event.data.url;

        setTimeout(() => {
            console.log('🔄 Reloading after navigation...');
            window.location.reload();
        }, 500);
    } else if (event.data.type === 'TEST_MESSAGE') {
        console.log('✅ Client received test message from service worker!');
    } else {
        console.warn('⚠️ Unhandled message type:', event.data);
    }
};

// ✅ Attach listener before registering the Service Worker
navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

navigator.serviceWorker.register('/sw.js', { scope: '/' })
    .then(reg => console.log('SW registered:', reg))
    .catch(err => console.error('SW registration failed:', err));

window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js', { scope: '/' }).then((registration) => {
        console.log('✅ Service Worker registered:', registration);

        // ✅ Re-attach the message listener here in case it was lost before registration
        navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

        // 🛠 Ensure listener is added after restoring from bfcache
        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                console.log("♻️ Page restored from BFCache, notifying service worker.");
                navigator.serviceWorker.ready.then((registration) => {
                    registration.active?.postMessage({ type: 'PAGE_RESTORED' });
                });
            }
        });
    }).catch((error) => {
        console.error('❌ Service Worker registration failed:', error);
    });
});



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
